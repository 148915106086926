import React, { PureComponent } from 'react'

import Weavescope from './Weavescope'
import Cerebro from './Cerebro'
import DockIDE from './DockIDE'
import Elasticsearch from './Elasticsearch'
import Glances from './Glances'
import Kibana from './Kibana'
import Portainer from './Portainer'
import Windturbine from './Windturbine'
import GeneralModule from './GeneralModule'
import IceSign from './IceSign'
import CommandOn from './CommandOn'
import CommandOff from './CommandOff'

const WEAVESCOPE = 'weavescope'
const CEREBRO = 'cerebro'
const DOCKIDE = 'dockide'
const ELASTICSEARCH = 'elasticsearch'
const GLANCES = 'glances'
const KIBANA = 'kibana'
const PORTAINER = 'portainer'
const WINDTURBINE = 'windturbine'
const GENERALMODULE = 'generalmodule'
const ICESIGN = 'icesign'
const COMMAND_ON = 'command_on'
const COMMAND_OFF = 'command_off'

export const icons = [
  WEAVESCOPE,
  CEREBRO,
  DOCKIDE,
  ELASTICSEARCH,
  GLANCES,
  KIBANA,
  PORTAINER,
  WINDTURBINE,
  GENERALMODULE,
  ICESIGN,
  COMMAND_ON,
  COMMAND_OFF
]

export default class Icon extends PureComponent {
  selectIcon = (icon) => {
    if (icon === WEAVESCOPE) {
      return <Weavescope />
    }
    if (icon === CEREBRO) {
      return <Cerebro />
    }
    if (icon === DOCKIDE) {
      return <DockIDE />
    }
    if (icon === ELASTICSEARCH) {
      return <Elasticsearch />
    }
    if (icon === GLANCES) {
      return <Glances />
    }
    if (icon === KIBANA) {
      return <Kibana />
    }
    if (icon === PORTAINER) {
      return <Portainer />
    }
    if (icon === WINDTURBINE) {
      return <Windturbine />
    }
    if (icon === GENERALMODULE) {
      return <GeneralModule />
    }
    if (icon === ICESIGN) {
      return <IceSign />
    }
    if (icon === COMMAND_ON) {
      return <CommandOn />
    }
    if (icon === COMMAND_OFF) {
      return <CommandOff />
    }
    return <div />
  }

  render() {
    const { children } = this.props
    return this.selectIcon(children)
  }
}
