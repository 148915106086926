import Cerebro from './icons/Cerebro'
import DockIDE from './icons/DockIDE'
import Elasticsearch from './icons/Elasticsearch'
import Glances from './icons/Glances'
import Kibana from './icons/Kibana'
import Portainer from './icons/Portainer'
import Weavescope from './icons/Weavescope'
import Windturbine from './icons/Windturbine'
import GeneralModule from './icons/GeneralModule'
import IceSign from './icons/IceSign'
import CommandOn from './icons/CommandOn'
import CommandOff from './icons/CommandOff'
import Icon, { icons } from './icons'

export const CerebroIcon = Cerebro
export const DockIDEIcon = DockIDE
export const ElasticsearchIcon = Elasticsearch
export const GlancesIcon = Glances
export const KibanaIcon = Kibana
export const PortainerIcon = Portainer
export const WeavescopeIcon = Weavescope
export const WindturbineIcon = Windturbine
export const GeneralModuleIcon = GeneralModule
export const IceSignIcon = IceSign
export const CommandOnIcon = CommandOn
export const CommandOffIcon = CommandOff

export const FasIcon = Icon

const iconsSet = new Set(icons)

export function isFasIcon(icon) {
  return iconsSet.has(icon)
}
